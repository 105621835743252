import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

export interface token {
  token: string;
  type: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  errorCode = {};
  user = {} as User;
  token = {};
  isAuthenticated = !!JwtService.getToken();
  routes = [];
  allowedActions = {};
  endpoints = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getError() {
    return this.errorCode;
  }

  /**
   * get Routes
   * @returns Array
   */
  get Routes() {
    return this.routes;
  }

  /**
   * get Routes
   * @returns Array
   */
  get Endpoints() {
    return this.endpoints;
  }

  /**
   * get Actions
   * @returns Object
   */
  get AllowedActions() {
    return (section) => {
      return this.allowedActions[section];
    };
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_ERROR_CODE](error) {
    this.errorCode = error;
  }

  @Mutation
  [Mutations.SET_ROUTES_AVAILABLES](routes) {
    this.routes = routes;
  }

  @Mutation
  [Mutations.SET_ACTIONS](actions) {
    this.allowedActions = actions;
  }

  @Mutation
  [Mutations.SET_ENDPOINTS](endpoints) {
    this.endpoints = endpoints;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    this.token = user;
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.routes = [];
    this.allowedActions = {};
    this.endpoints = [];
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('routes');
    window.localStorage.removeItem('actions');
    window.localStorage.removeItem('endpoints');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('deadline');
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("auth/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        window.localStorage.setItem('token', JSON.stringify(data));
        ApiService.get('auth/profile', { headers: {
          Authorization: 'Bearer '+data.token
        }
        }).then((res) => {
          window.localStorage.setItem('routes', JSON.stringify(res.data.routes));
          window.localStorage.setItem('actions', JSON.stringify(res.data.actions));
          window.localStorage.setItem('endpoints', JSON.stringify(res.data.endpoints));
          window.localStorage.setItem('email', credentials.email);
          this.context.commit(Mutations.SET_USER, {email: credentials.email});
          this.context.commit(Mutations.SET_ROUTES_AVAILABLES, res.data.routes)
          this.context.commit(Mutations.SET_ACTIONS, res.data.actions)
          this.context.commit(Mutations.SET_ENDPOINTS, res.data.endpoints)
        }).catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.statusText);
          this.context.commit(Mutations.SET_ERROR_CODE, response)
        });
      })
      .catch((error: any) => {
        switch (error.code) {
          case "ERR_NETWORK":
          case "ECONNREFUSED":
            console.log(
              "¡No se ha podido establecer la comunicación con el servidor:"
            );
            this.context.commit(
              Mutations.SET_ERROR,
              "¡No se ha podido establecer la comunicación con el servidor:"
            );
            break;
          case "ERR_BAD_REQUEST":
            console.log("ERR_BAD_REQUEST", `${error.address}:${error.port}:`);
            if (error.response.status == 403) {
              console.log(
                "¡No tienes suficientes permisos para acceder a este recurso!"
              );
              this.context.commit(
                Mutations.SET_ERROR,
                "¡No tienes suficientes permisos para acceder a este recurso!"
              );
            }

            if (error.response.status == 400 || error.response.status == 404) {
              if (error.response.data.errors) {
                const message = String(
                  Object.values(error.response.data.errors[0].constraints)[0]
                );
                this.context.commit(Mutations.SET_ERROR, message);
              } else {
                this.context.commit(
                  Mutations.SET_ERROR,
                  error.response?.data?.message
                );
              }
            }

            if (error.response.status == 422) {
              this.context.commit(Mutations.SET_ERROR, error?.message);
            }

            break;
          default:
            console.log("error 500:", `${error.address}:${error.port}`, error);
            this.context.commit(Mutations.SET_ERROR, "error 500");
            break;
        }

        this.context.commit(Mutations.SET_ERROR_CODE, error.response);
      });
  }

  @Action
  [Actions.LOGOUT](credentials) {
    return ApiService.post("auth/logout", credentials)
    .then(({ data }) => {
        this.context.commit(Mutations.PURGE_AUTH);
      })
      .catch((error: any) => {

        switch (error.code) {
          case 'ERR_NETWORK':
          case 'ECONNREFUSED':
            console.log('¡No se ha podido establecer la comunicación con el servidor:');
            this.context.commit(Mutations.SET_ERROR, '¡No se ha podido establecer la comunicación con el servidor:');
            break;
          case 'ERR_BAD_REQUEST':

            console.log('ERR_BAD_REQUEST', `${error.address}:${error.port}:`);
            if(error.response.status == 403) {

              console.log('¡No tienes suficientes permisos para acceder a este recurso!');
              this.context.commit(Mutations.SET_ERROR, '¡No tienes suficientes permisos para acceder a este recurso!');

            }

            if(error.response.status == 400 || error.response.status == 404 ) {

              if(error.response.data.errors) {

                const message = String(Object.values(error.response.data.errors[0].constraints)[0]);
                this.context.commit(Mutations.SET_ERROR, message);

              }else{

                this.context.commit(Mutations.SET_ERROR, error.response?.data?.message);

              }

            }

            if(error.response.status == 422){

              this.context.commit(Mutations.SET_ERROR, error?.message);

            }

            break;
          default:
            console.log('error 500:', `${error.address}:${error.port}`, error);
            this.context.commit(Mutations.SET_ERROR, 'error 500');
            break;
        }

        this.context.commit(Mutations.SET_ERROR_CODE, error.response);

      });
  }

  @Action
  [Actions.TIMESTAMP]() {
    return ApiService.get("auth/timestamp")
    .then(({ data }) => {
        return data.timestamp;
      })
      .catch((error: any) => {

        switch (error.code) {
          case 'ERR_NETWORK':
          case 'ECONNREFUSED':
            console.log('¡No se ha podido establecer la comunicación con el servidor:');
            this.context.commit(Mutations.SET_ERROR, '¡No se ha podido establecer la comunicación con el servidor:');
            break;
          case 'ERR_BAD_REQUEST':

            console.log('ERR_BAD_REQUEST', `${error.address}:${error.port}:`);
            if(error.response.status == 403) {

              console.log('¡No tienes suficientes permisos para acceder a este recurso!');
              this.context.commit(Mutations.SET_ERROR, '¡No tienes suficientes permisos para acceder a este recurso!');

            }

            if(error.response.status == 400 || error.response.status == 404 ) {

              if(error.response.data.errors) {

                const message = String(Object.values(error.response.data.errors[0].constraints)[0]);
                this.context.commit(Mutations.SET_ERROR, message);

              }else{

                this.context.commit(Mutations.SET_ERROR, error.response?.data?.message);

              }

            }

            if(error.response.status == 422){

              this.context.commit(Mutations.SET_ERROR, error?.message);

            }

            break;
          default:
            console.log('error 500:', `${error.address}:${error.port}`, error);
            this.context.commit(Mutations.SET_ERROR, 'error 500');
            break;
        }

        this.context.commit(Mutations.SET_ERROR_CODE, error.response);

      });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        ApiService.setHeader();
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      const routes = window.localStorage.getItem("routes");
      const user = window.localStorage.getItem("user");
      const actions = window.localStorage.getItem("actions");
      const endpoints = window.localStorage.getItem("endpoints");
      if (routes) {
        this.context.commit(
          Mutations.SET_ROUTES_AVAILABLES,
          JSON.parse(routes)
        );
      }
      if (user) {
        this.context.commit(Mutations.SET_AUTH, user);
      }
      if (actions) {
        this.context.commit(Mutations.SET_ACTIONS, JSON.parse(actions));
      }
      if (endpoints) {
        this.context.commit(Mutations.SET_ENDPOINTS, JSON.parse(endpoints));
      }
      ApiService.setHeader();
      // ApiService.post("verify_token", payload)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action({ rawError: true })
  async [Actions.PASSWORD_RECOVERY](payload: object) {
    try {
      const { data } = await ApiService.post("auth/password-recovery", payload);
      return data;
    } catch (error: any) {
      const { data, status } = error.response;
      throw { status, message: data.message };
    }
  }

  @Action({ rawError: true })
  async [Actions.SET_PASSWORD](payload: object) {
    try {
      const response = await ApiService.post("auth/set-password", payload);
      return response.data;
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error.response.data, "errors")) {
        throw {
          status: error.response.status,
          message: error.response.data.errors[0].message,
        };
      }
      const { data, status } = error.response;
      throw { status, message: data.message };
    }
  }
}
