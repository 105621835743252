
import { defineComponent, nextTick, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
// Import component
import loading from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import {useRouter} from "vue-router";
import store from "@/store";

export default defineComponent({
  name: "app",
  components: {
    loading
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loadingState = computed(() => store.getters.loadingValue)
    const isLoading = ref(false);
    const fullPage = ref(true);
    const onCancel = () => {
      console.log('User cancelled the loader.');
      //because the props is single flow direction, you need to set isLoading status normally.
      isLoading.value = false;
    }
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });

    onMounted(() => {

      const broadcast = new BroadcastChannel('tabs_channel');

      broadcast.onmessage = async function(event: any) {

        if (event.data.command === 'redirectToSignInAllExceptMe' && !document.hasFocus()) {

          await store.dispatch(Mutations.PURGE_AUTH, {});
          await router.push({ name: 'sign-in' });

        }

        if (event.data.command === 'reloadAllExceptMe' && !document.hasFocus()) {

          window.location.reload();

        }

        if (event.data.command === 'refreshCountdownAllExceptMe' && !document.hasFocus()) {

          await store.dispatch("timer/setInactivityTime", new Date().getTime());

        }

      };

    });

    return {
      isLoading,
      fullPage,
      loadingState,
      onCancel
    }
  },
});
