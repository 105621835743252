enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  TIMESTAMP = "timestamp",
  TOKEN_GENERATE = "tokenGenerate",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_DOCUMENT_PARAMS = "setDocumentParamsAction",
  SET_DOCUMENT_CODE = "setDocumentCodeAction",
  SET_DOCUMENT_DETAIL = "setDocumentDetailAction",
  SET_DOCUMENT_LIST = "setDocumentListAction",
  PASSWORD_RECOVERY = "password-recovery",
  SET_PASSWORD = "set-password",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_LOADING_VALUE = "setLoadingValue",
  SET_ROUTES_AVAILABLES = "setRoutes",
  SET_ACTIONS = "setActions",
  SET_ENDPOINTS = "setEndpoints",
  SET_ERROR_CODE = "setErrorCode",
  SET_DOCUMENT_PARAMS = "setDocumentParamsMutation",
  SET_DOCUMENT_CODE = "setDocumentCodeMutation",
  SET_DOCUMENT_DETAIL = "setDocumentDetailMutation",
  SET_DOCUMENT_LIST = "setDocumentListMutation",
}

export { Actions, Mutations };
