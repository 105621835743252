import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loadingState,
      "can-cancel": true,
      "on-cancel": _ctx.onCancel,
      "is-full-page": _ctx.fullPage
    }, null, 8, ["active", "on-cancel", "is-full-page"]),
    _createVNode(_component_router_view)
  ], 64))
}